import {
  Drawer,
  drawerStyles,
} from '@/components/system/Layout/LayoutWebCore/WebCoreAppBar/AppDrawer/styles';
import AppSideBarDrawerHeader from '@/components/system/Layout/LayoutWebCore/WebCoreAppBar/SideBarHeader';
import Scrollbar from '@/components/ui/Scrollbar';
import PromotionsBanner from '@/components/system/Layout/LayoutWebCore/WebCoreAppBar/promotionsBanner';
import Menus from '@/components/system/Layout/LayoutWebCore/WebCoreAppMenus';
import React from 'react';
import { Divider } from '@mui/material';
import { RewardsSection } from '@/components/system/Layout/LayoutWebCore/WebCoreAppBar/AppDrawer/AppBarContent';

export const MainDrawer = ({ appSidebarOpen, isMobile }) => (
  <Drawer variant="permanent" open={appSidebarOpen} sx={drawerStyles}>
    <AppSideBarDrawerHeader isMobile={isMobile} />
    <Scrollbar autoHide offsetY={appSidebarOpen ? -28 : -18}>
      <RewardsSection appSidebarOpen={appSidebarOpen} />
      <Divider sx={{ mb: 2, mt: 2 }} />
      <PromotionsBanner />
      <Menus open={appSidebarOpen} />
    </Scrollbar>
  </Drawer>
);
