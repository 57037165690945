import {
  APP_SIDE_BAR_COLLAPSE_WIDTH,
  APP_SIDE_BAR_EXPAND_WIDTH,
} from '@/constants/global';
import {
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Toolbar as MuiToolbar,
} from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import { styled } from '@mui/material/styles';
import { closedMixin, openedMixin } from '../mixins';
import { isIndieGameView } from '@/utils/checkRuntime';

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  transition: theme.transitions.create('background'),
}));

export const LogoBox = styled('div')(({ theme }) => ({
  ['> img']: {
    verticalAlign: 'bottom',
  },
}));

export const drawerStyles = (theme) => ({
  background: theme.palette.primary.main,
  display: { xs: 'none', sm: 'block' },
  '& .MuiDrawer-paper': {
    overflow: 'hidden',
  },
});

export const ProfileBox = styled('div')(({ theme }) => ({}));

export const AppHeaderBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isIndieGameView',
})<MuiAppBarProps & { open: boolean; isIndieGameView?: boolean }>(
  ({ theme, open, isIndieGameView }) => ({
    background: 'transparent',
    width: isIndieGameView
      ? '100%'
      : `calc(100% - ${APP_SIDE_BAR_COLLAPSE_WIDTH + 1}px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    ...(!isIndieGameView &&
      open && {
        marginLeft: APP_SIDE_BAR_EXPAND_WIDTH,
        width: `calc(100% - ${APP_SIDE_BAR_EXPAND_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
  }),
);

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  position: 'relative',
  width: APP_SIDE_BAR_EXPAND_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      padding: theme.spacing(2.5, 2.5, 4, 2.5),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      padding: theme.spacing(2.5, 1.25, 4, 1.25),
    },
  }),
}));

export const AppMain = styled('main', {
  shouldForwardProp: (prop) => 
    !['open', 'isSportsPage', 'isIndieGameView'].includes(prop as string),
})<{ open: boolean; isSportsPage: boolean; isIndieGameView?: boolean }>(
  ({ theme, open, isSportsPage }) => ({
    maxWidth: '100%',
    minHeight: '100%',
    width: isIndieGameView
      ? '100%'
      : `calc(100% - ${APP_SIDE_BAR_COLLAPSE_WIDTH + 1}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: 'relative',
    marginLeft: 'auto',
    overflowX: 'hidden',
    ...(!isIndieGameView &&
      open && {
        marginLeft: APP_SIDE_BAR_EXPAND_WIDTH,
        width: `calc(100% - ${APP_SIDE_BAR_EXPAND_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    backgroundColor: theme.palette.bg.main,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      backgroundImage: 'none',
      backgroundColor: theme.palette.bg.main,
    },
  }),
);
