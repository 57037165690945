import { isTheaterModeOn } from '@/state/gameSetting';
import useTheme from '@/theme/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from './WebCoreAppBar';
import dynamic from 'next/dynamic';
import { useAtomValue } from 'jotai';
import { AppCoreContainerStyle } from './styles';
import { useRouter } from 'next/router';
import GlobalStyle from '@/theme/globalStyle';
import { PropsWithChildren } from 'react';
import BlockedCountryModal from '@/components/biz/BlockedCountryModal';
import {
  isIndieGameView,
  isTelegramMiniAppView,
  isWebCoreView,
} from '@/utils/checkRuntime';

const BreadcrumbNav = dynamic(() => import('@/components/ui/BreadcrumbNav'), {
  ssr: false,
});
const BetsBox = dynamic(() => import('@/components/features/common/Bets'), {
  ssr: false,
});

const AppMobileBottomBar = dynamic(
  () => import('@/components/system/Layout/LayoutWebCore/AppMobileBottomBar'),
  {
    ssr: false,
  },
);

const Footer = dynamic(
  () => import('@/components/system/Layout/LayoutWebCore/Footer'),
  {
    ssr: false,
  },
);

export interface SystemLayoutProps extends PropsWithChildren {
  appSidebarOpened?: boolean;
  enableAppBar?: boolean;
}

export function AppCoreContainer(props: SystemLayoutProps) {
  const { enableAppBar = true, children, appSidebarOpened } = props;
  const { pathname } = useRouter();
  const { theme } = useTheme();
  const isTheaterMode = useAtomValue(isTheaterModeOn);
  const isSportsPage = pathname === '/sports';
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const showBetsBox = () => {
    return isWebCoreView && pathname?.includes('/casino');
  };

  const showBreadcrumbNav = () => {
    return isWebCoreView && !pathname.includes('/casino');
  };

  const CommonLayout = (
    <>
      <GlobalStyle />
      {/*<BlockedCountryModal />*/}
      {showBreadcrumbNav() && <BreadcrumbNav />}
      {children}
      {showBetsBox() && <BetsBox />}
      {/*<ControlInviteTgModal />*/}
    </>
  );

  return (
    <>
      <AppBar appSidebarOpened={appSidebarOpened}>
        <AppCoreContainerStyle
          threaterMode={isTheaterMode}
          isSportsPage={isSportsPage}
        >
          {CommonLayout}
        </AppCoreContainerStyle>
        {!isIndieGameView && <Footer />}
        {!isIndieGameView && isSmallScreen && <AppMobileBottomBar />}
      </AppBar>
    </>
  );
}

export default AppCoreContainer;
