const baseDefaultConfig = {
  name: 'production',
  endpoints: {
    domain: 'https://www.moonclub.com',
    baseAPI: 'https://api.moonclub.com',
    websocket: 'wss://api.moonclub.com/api/game-ws',
  },
};

const env = {
  ...baseDefaultConfig,
  name: 'localhost',
  isLocal: true,
  hostname: 'localhost',
  internalDomains: {
    localhost: 1,
  },
  endpoints: {
    domain: 'localhost',
    assets: '',
    baseAPI: 'https://api.beta.moonclub.com',
    websocket: 'wss://api.beta.moonclub.com/api/game-ws',
    imgUploadUrl: '',
    imgThumbnailUrl: '',
    strapi: 'https://api.blog.moonclub.com/',
  },
  fingerPointDeviceHost: 'https://api.commonservice.io',
  polaris: {
    host: 'https://www.devfdg.net',
    appId: '',
    saasId: '',
    token: '',
  },
  // Uncomment for use with `smartling-context-capture` package
  useSmartlingContextCapture: true,
};

module.exports = env;
