const coreWebEnvironmentConfig = {
  local: {
    name: 'localhost',
    endpoints: {
      telegramBot: 'https://t.me/moonclub_local_bot',
      domain: 'http://0.0.0.0:4200',
      baseAPI: 'https://api.beta.moonclub.com',
      websocket: 'wss://api.beta.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://beta.affiliates.moonclub.com/',
    },
  },
  'local-telegram': {
    name: 'localhost',
    endpoints: {
      telegramBot: 'https://t.me/moonclub_local_bot',
      domain: 'http://0.0.0.0:4200',
      baseAPI: 'https://api.beta.moonclub.com',
      websocket: 'wss://api.beta.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://beta.affiliates.moonclub.com/',
    },
  },
  beta: {
    name: 'development',
    hostname: 'beta.moonclub.com',
    endpoints: {
      telegramBot: 'https://t.me/moonclub_tg_beta_bot',
      domain: 'https://beta.moonclub.com',
      baseAPI: 'https://api.beta.moonclub.com',
      websocket: 'wss://api.beta.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://beta.affiliates.moonclub.com/',
    },
  },
  'beta-telegram': {
    name: 'beta-telegram',
    endpoints: {
      telegramBot: 'https://t.me/moonclub_tg_beta_bot',
      domain: 'https://tg.beta.moonclub.com/',
      baseAPI: 'https://api.beta.moonclub.com',
      websocket: 'wss://api.beta.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://beta.affiliates.moonclub.com/',
    },
  },
  staging: {
    hostname: 'staging.moonclub.com',
    endpoints: {
      telegramBot: 'https://t.me/moonclub_staging_bot',
      domain: 'https://staging.moonclub.com',
      baseAPI: 'https://api.staging.moonclub.com',
      websocket: 'wss://api.staging.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://affiliates.moonclub.com/',
    },
  },
  'staging-telegram': {
    endpoints: {
      telegramBot: 'https://t.me/moonclub_staging_bot',
      domain: 'https://tg.staging.moonclub.com',
      baseAPI: 'https://api.staging.moonclub.com',
      websocket: 'wss://api.staging.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://affiliates.moonclub.com/',
    },
  },
  production: {
    GOOGLE_TAG_MANAGER_ID: 'GTM-TLH4L3WM',
    hostname: 'www.moonclub.com',
    endpoints: {
      telegramBot: 'https://t.me/MoonClubComBot',
      domain: 'https://www.moonclub.com',
      baseAPI: 'https://api.moonclub.com',
      websocket: 'wss://api.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://affiliates.moonclub.com/',
    },
  },
  'production-telegram': {
    GOOGLE_TAG_MANAGER_ID: 'GTM-TLH4L3WM',
    endpoints: {
      telegramBot: 'https://t.me/MoonClubComBot',
      domain: 'https://www.tg.moonclub.com/',
      baseAPI: 'https://api.moonclub.com',
      websocket: 'wss://api.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://affiliates.moonclub.com/',
    },
  },
};

module.exports = coreWebEnvironmentConfig;
