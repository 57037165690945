const envConfig = require('./base');
const coreWebEnvironmentConfig = require('./core-web');
const indieGameBoxEnvironmentConfig = require('./indie-games/box');
const indieGameSquidEnvironmentConfig = require('./indie-games/squid');

const env = process.env.NEXT_PUBLIC_MOON_ENV || 'production';
const environmentConfig = Object.assign(
  {},
  coreWebEnvironmentConfig,
  indieGameBoxEnvironmentConfig,
  indieGameSquidEnvironmentConfig,
);

Object.assign(envConfig, environmentConfig[env]);

module.exports = envConfig;
