import CatchError from '@/components/system/CacthError';
import { APP_FIXED_HEADER_HEIGHT } from '@/constants/global';
import {
  isLoggedIn,
  isTemporaryLoginWithoutToken,
  userInfoMetaData,
} from '@/state/userInfo';
import useUserLoginCallbackHandler from '@/hooks/biz/useUserLoginCallbackHandler';

import useTheme from '@/theme/useTheme';
import { Divider } from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React from 'react';
import { AppHeaderBar, AppMain } from './styles';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';
import { useAppDrawer } from './hooks/useAppDrawer';

import { MainDrawer } from './MainDrawer';
import { AppBarContent } from './AppBarContent';
import Loading from '@/components/ui/Loading';
import { isIndieGameView } from '@/utils/checkRuntime';
import { store } from '@/state/store';

const BindEmailModal = dynamic(
  () => import('@/components/system/Auth/BindEmailModal'),
  {
    ssr: false,
    loading: () => <Loading size={'50px'} />,
  },
);
const DrawerHeader = dynamic(
  () => import('./DrawerHeader'),
  {
    ssr: false,
  },
);
export interface AppBarProps extends MuiAppBarProps {
  appSidebarOpened?: boolean;
  children?: React.ReactNode;
}

export function AppDrawer(props: AppBarProps) {
  const { children } = props;
  const { theme } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useRouter();

  const { appSidebarOpen, toggleDrawerOpen, handleHeaderIconClick } =
    useAppDrawer(isMobile);
  useUserLoginCallbackHandler();
  const loggedIn = useAtomValue(isLoggedIn);
  const isTemporaryLogin = useAtomValue(isTemporaryLoginWithoutToken);
  const { userMetaData } = useAtomValue(userInfoMetaData);
  const [modals, setModals] = useAtom(globalModals);

  return (
    <>
      <AppHeaderBar
        position="fixed"
        open={Boolean(appSidebarOpen)}
        isIndieGameView={isIndieGameView}
      >
        {!isIndieGameView && (
          <DrawerHeader
            appSidebarOpen={appSidebarOpen}
            toggleDrawerOpen={toggleDrawerOpen}
            isMobile={isMobile}
          />
        )}
        <AppBarContent
          isMobile={isMobile}
          loggedIn={loggedIn}
          isTemporaryLogin={isTemporaryLogin}
        />
      </AppHeaderBar>

      {!isIndieGameView && (
        <MainDrawer appSidebarOpen={appSidebarOpen} isMobile={isMobile} />
      )}

      <AppMain
        open={appSidebarOpen}
        isIndieGameView={isIndieGameView}
        isSportsPage={pathname === '/sports'}
      >
        <Divider
          sx={{
            marginTop: APP_FIXED_HEADER_HEIGHT + 'px',
            borderBottom: 'none',
          }}
        />
        {children}
      </AppMain>

      <BindEmailModal
        thirdPartyLoginType={userMetaData?.thirdPartyLoginType}
        open={modals[GLOBAL_MODALS.BIND_EMAIL]}
        onClose={() => setModals({ [GLOBAL_MODALS.BIND_EMAIL]: false })}
        tgUserString={userMetaData?.thirdPartyAuthCode}
      />
    </>
  );
}

export default CatchError(AppDrawer);
