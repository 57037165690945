// Description: Check runtime environment, all environment variables are setting in .env file
// All environment variables configuration are defined in apps/web/configs/env dir

// Common runtime environment variables
export const checkRuntime = typeof window === 'undefined';
export const isLocalDev = process.env.NEXT_PUBLIC_MOON_ENV.includes('local');
export const isBeta = process.env.NEXT_PUBLIC_MOON_ENV.includes('beta');
export const isTest = isLocalDev || isBeta;

export const isProduction =
  process.env.NEXT_PUBLIC_MOON_ENV.includes('production');
export const isRelease = ['production', 'staging', 'pre'].includes(
  process.env.NEXT_PUBLIC_MOON_ENV,
);

export const isTelegramMiniAppView =
  process.env.NEXT_PUBLIC_MOON_ENV?.includes('telegram');

// indie game common runtime environment variables
export const isIndieGameView =
  process.env.NEXT_PUBLIC_MOON_ENV.includes('indie-game');

// Web Core  runtime environment variables
export const isWebCoreView = !isIndieGameView && !isTelegramMiniAppView;

// indie game FOMO BOX runtime environment variables
export const isFomoBoxGame = process.env.NEXT_PUBLIC_MOON_ENV.includes(
  'indie-game-fomo-box',
);

// indie game squid box runtime environment variables
export const isSquidGame =
  process.env.NEXT_PUBLIC_MOON_ENV.includes('indie-game-squid');
