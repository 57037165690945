// hooks/useAppDrawer.ts
import { useAtom, useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import {
  appSidebarOpenState,
  appSidebarMobileOpenState,
} from '@/state/globalSettings';
import { cookie } from '@/utils/cookies';
import { COOKIE_KEY_APP_SIDE_BAR_OPEN_STATUS } from '@/constants/cookieStorageKeys';

export const useAppDrawer = (isMobile: boolean) => {
  const [appSidebarOpen, setAppSidebarOpen] = useAtom(appSidebarOpenState);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sidebarMobileOpenState, setSidebarMobileOpenState] = useAtom(
    appSidebarMobileOpenState,
  );

  const handleMobileDrawerToggle = () => {
    if (!isMobile) return;
    setMobileOpen(!mobileOpen);
  };

  const toggleDrawerOpen = () => {
    setAppSidebarOpen(!appSidebarOpen);
    cookie.set(COOKIE_KEY_APP_SIDE_BAR_OPEN_STATUS, !appSidebarOpen);
  };

  const handleHeaderIconClick = () => {
    !isMobile ? toggleDrawerOpen() : setSidebarMobileOpenState(false);
  };

  return {
    appSidebarOpen,
    mobileOpen,
    toggleDrawerOpen,
    handleMobileDrawerToggle,
    handleHeaderIconClick,
  };
};
