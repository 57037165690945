import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import CatchError from '@/components/system/CacthError';
import getI18nProps from '@/utils/i18/getI18nProps';

 const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  fontSize: '1.2rem',
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.primary.main,
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

export const FomoBoxLandingPage = () => {
  const router = useRouter();

  const handleEnterGame = () => {
    router.push('/games/box');
  };

  return (
    <StyledContainer maxWidth="md">
      <Typography variant="h2" component="h1" gutterBottom>
        FOMO Box Game
      </Typography>

      <Typography variant="h5" color="textSecondary" gutterBottom>
        Experience the thrill of the ultimate crypto gaming adventure
      </Typography>

      <Box mt={4}>
        <StyledButton
          variant="contained"
          onClick={handleEnterGame}
        >
          Enter Game
        </StyledButton>
      </Box>
    </StyledContainer>
  );
};

export async function getServerSideProps({ locale, res, req }) {
  return {
    props: {
      ...(await getI18nProps({ locale })),
    },
  };
}

export default CatchError(FomoBoxLandingPage);