import AppBarTools from '@/components/system/Layout/LayoutWebCore/WebCoreAppBar/ToolBar';
import TelegramMiniAppAutoLogin from '@/components/system/Auth/TelegramMiniAppAutoLogin';

import MoonLogo from '@/components/biz/MoonLogo';
import { ProfileBox } from '@/components/system/Layout/LayoutWebCore/WebCoreAppBar/styles';
import { isTelegramMiniAppView } from '@/utils/checkRuntime';
import RewardsDropdown from '../SideBarHeader/rewards';
import AuthModal from '@/components/system/Auth/AuthModal';
import dynamic from 'next/dynamic';
import React from 'react';
import { Box } from '@mui/material';
import LanguageSwitcher from '@/components/system/LanguageSwitcher';

export const RewardsSection = ({
  appSidebarOpen,
}: {
  appSidebarOpen?: boolean;
}) => {
  return (
    <Box
      sx={{
        height: '42px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <RewardsDropdown />
    </Box>
  );
};
const WalletWidget = dynamic(
  () => import('@/components/features/wallet/WalletWidget'),
  {
    ssr: false,
  },
);

const WalletWidgetPlaceholder = dynamic(
  () => import('@/components/features/wallet/WalletWidgetPlaceholder'),
  {
    ssr: false,
  },
);

const renderWalletComponent = (
  isTemporaryLogin: boolean,
  loggedIn: boolean,
) => {
  if (isTemporaryLogin) {
    return <WalletWidgetPlaceholder />;
  }
  if (loggedIn) {
    return <WalletWidget />;
  }
  return null;
};

export const AppBarContent = ({ isMobile, loggedIn, isTemporaryLogin }) => (
  <AppBarTools>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      flex: '1 1 0',
      justifyContent: 'flex-start' 
    }}>
      <MoonLogo   />
    </Box>
    
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      flex: '1 1 0',
      justifyContent: 'center' 
    }}>
      {renderWalletComponent(isTemporaryLogin, loggedIn)}
    </Box>

    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      flex: '1 1 0',
      justifyContent: 'flex-end' 
    }}>
      <ProfileBox
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isTelegramMiniAppView ? <TelegramMiniAppAutoLogin /> : <AuthModal />}
      </ProfileBox>
    </Box>
  </AppBarTools>
);
