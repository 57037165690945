import { atom } from 'jotai';
import { TUserInfo } from '@/types/user';
import {
  PlayerBonusInfoState,
  userBonusInfoState,
  userInfoState,
  userWalletInfoState,
} from './state';
import { CURRENCY_NAME } from '@/constants/currency';
import { isTelegramMiniAppView } from '@/utils/checkRuntime';

// if name exists, but token not exists, it means user is temporary login(the third party login)
// and the token will be generated after the user bind the email
export const isTemporaryLoginWithoutToken = atom((get) => {
  const userState = get(userInfoState);
  return (
    // isTelegramMiniApp &&
    !!userState?.userMetaData?.name && !userState?.userMetaData?.token
  );
});

export const isLoggedIn = atom((get) => {
  const userState = get(userInfoState);
  return !!userState?.userMetaData?.token || false;
});

export const isUserinfoLoading = atom((get) => {
  const userState = get(userInfoState);
  return userState?.isUserLoading;
});

export const userInfoMetaData = atom((get) => get(userInfoState));

export const updateUserInfoMeta = atom(
  (get) => get(userInfoState),
  (
    get,
    set,
    newValue: {
      userMetaData: Partial<TUserInfo> | undefined;
      isUserLoading?: boolean;
    },
  ) => {
    set(userInfoState, (prevValue) => ({
      ...prevValue,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userMetaData: !newValue.userMetaData
        ? null
        : {
            ...(prevValue.userMetaData || {}),
            ...newValue.userMetaData,
          },
      isUserLoading: false,
    }));
  },
);
export const walletInfoCurrentSelectedWallet = atom(
  (get) => {
    const walletInfo = get(userWalletInfoState);
    let wallet = walletInfo.wallets.find(
      (w) => w.id === walletInfo.currentSelectedWallet,
    );
    if (!wallet) {
      wallet = walletInfo.wallets[0];
    }
    return wallet;
  },
  (get, set, newValue: string) => {
    const prevValue = get(userWalletInfoState);
    set(userWalletInfoState, {
      ...prevValue,
      currentSelectedWallet: newValue,
    });
  },
);

export const currentCurrencyState = atom((get) => {
  const walletInfo = get(walletInfoCurrentSelectedWallet);

  if (!walletInfo) return CURRENCY_NAME.USDT;
  return walletInfo.coin;
});
// export const currentDecimal = atom((get) => {
//   const walletInfo = get(walletInfoCurrentSelectedWallet);
//   if (!walletInfo) return USDT_DECIMAL;
//   return getDecimal(walletInfo.coin);
// });

export const updateUserBonusInfo = atom(
  (get) => {
    return get(userBonusInfoState);
  },
  (get, set, newValue: Partial<PlayerBonusInfoState>) => {
    set(userBonusInfoState, (prevValue) => {
      return Object.assign(prevValue, newValue);
    });
  },
);
