import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import CTextField from '../CTextField';

export const CustomSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'hidearrow'
})<{
  hidearrow?: boolean;
}>(({ theme, hidearrow }) => ({
  // background: theme.palette.bg.secondary,
  border: theme.palette.border.normal,
  padding: 0,
  height: '48px',
  alignItems: 'stretch',
  '& .MuiSelect-select': {
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: '1px solid transparent',
    fontSize: theme.typography.pxToRem(14),
  },

  '& .MuiInputBase-input': {
    paddingRight: hidearrow ? '0px !important' : undefined,
  },

  '& .MuiSelect-icon': {
    display: hidearrow ? 'none' : undefined,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent !important',
  },
}));

export const WrappedSearchInput = styled(CTextField)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  width: '100%',
  boxSizing: 'border-box',

  '& .MuiInputBase-root': {
    ['&:hover > .MuiOutlinedInput-notchedOutline,&:focus-within > .MuiOutlinedInput-notchedOutline']:
      {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
  },
  [`& .MuiOutlinedInput-root`]: {
    height: '40px',
  },
  ['& .MuiInputBase-input']: {
    padding: theme.spacing(0),
    height: '100%',
    paddingInlineStart: theme.spacing(2),
  },
}));
